<template>
  <v-autocomplete class="mb-2"
      :value="select.id"
      v-model="select"
      :loading="loading"
      :items="allTimezonesItems"
      outlined
      hide-no-data
      hide-details
      :rules="rules"
      prepend-inner-icon="mdi-map-clock"
      placeholder="Выберите свой часовой пояс"
      color="grey"
      @input="update"
    ></v-autocomplete>
</template>

<script>
export default {
    props: ['value', 'rules'],
    data() {
        return{
            loading: false,
            search: "",
            select: this.value ? this.value : 0,
        }
    },
    computed:{
        allTimezones(){ 
            return this.$store.getters['company/sale_point_timezones/allTimezones']
        },
        allTimezonesItems(){
            const timezones = []
            this.$store.getters['company/sale_point_timezones/allTimezones'].forEach((el) =>{
                timezones.push(el)
            })
            timezones.forEach((el) => {
                el.value = el.id
                el.text = el.name_ru + " GMT" + el.offset_name
            })
            return timezones
        }
    },
    methods:{
        update(){
            if (this.select && this.allTimezones)
            {
                console.log("EMITED SELECT")
                console.log(this.select)
                this.$emit('input', this.select)
            }
        }
    }
}
</script>

<style lang="scss">

</style>

